.modal {
  width: 500px;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

.modal h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}
.modal .content {
  padding: 1rem;
}
.modal .actions {
  border-top: 1px solid #ccc;
  padding: 0.5rem 1rem;
}
.modal .actions button {
  border: 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  line-height: 1;
}
#centered-toggle-button {
  position: absolute;
}
