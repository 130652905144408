.table-setup {
  table-layout: fixed;
  width: 100%;
  padding-top: 0.5em;
  margin-bottom: 1em;
}

/* .printSetting {
  page-break-inside: avoid !important;
  page-break-after: always !important;
} */
