.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-width: 1px;
  overflow: hidden;
  padding: 7.5px 5px;
  word-break: normal;
}
.tg th {
  border-width: 1px;
  overflow: hidden;
  padding: 7.5px 5px;
  word-break: normal;
}
.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
}
