.flex-show {
  display: flex;
  flex-direction: column;
  flex-basis: 1;
}

@media print {
  @page {
    display: none !important;
    height: 0px;
    width: 0px;
    margin: 1cm;
  }
  .flex-show {
    display: block;
    width: auto;
  }

  .table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .table-body {
    display: table-row-group;
    vertical-align: middle;
  }

  .table-head {
    display: table-header-group;
  }

  .table-row {
    display: table-row;
  }

  .table-cell {
    display: table-cell;
  }

  .full-width {
    width: 100%;
  }

  .pageborder {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid gray;
    z-index: 999;
  }
  .print {
    visibility: visible !important;
    display: inline-block !important;
    page-break-before: always !important;
    page-break-inside: avoid !important;
    /* position: absolute; */
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    font-size: 12px !important;
    margin-left: 0;
  }

  .noprint {
    /* min-width: 50px !important; */
    display: inline-block !important;
    visibility: hidden !important;
    height: 0px !important;
    min-width: 0px !important;
    /* display: none; */
  }

  .printSetting {
    page-break-inside: avoid !important;
    page-break-after: always !important;
  }

  .noprint,
  .spec {
    margin-top: -150px;
  }
}
