.center-inputs {
  align-items: center;
}

@media (min-width: 770px) {
  .center-inputs {
    align-items: flex-start;
  }
}

.grid {
  display: grid;
  grid-template-columns: 20vw auto 20vw;
}

.sidebar-left {
  width: 20vw;
  position: fixed;
}

.sidebar-right {
  width: 20vw;
  position: fixed;
  right: 0;
}
.main-content {
  grid-column: 2;
}
