@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  text-decoration: none;
  /* background-color: white;
  border: none; */
}

*:focus {
  /* outline: 0 !important; */
}

body,
a,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: hsl(218 17% 35%);
  font-size: 0.9375rem;
  line-height: 1.5;
  min-height: 100vh;
  display: grid;
}

img,
picutre {
  max-width: 100%;
  display: block;
  border-style: none;
}

button {
  background: white;
  border: 0px;
  cursor: pointer;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-items {
  align-items: center;
}

.align-self {
  align-self: end;
}

.bg-green {
  background-color: rgba(110, 231, 183, 0.63);
}

.bg-dark {
  background-color: rgba(74, 85, 104, 1);
}

.bg-white {
  background-color: hsl(0 19% 96%);
}

.color-grey {
  color: hsl(0 0% 86%);
}

.text-green {
  color: rgba(110, 231, 183, 0.63);
}

.text-dark {
  color: rgba(74, 85, 104, 1);
}

.text-white {
  color: hsl(0 19% 96%);
}

.ff-sans-normal {
  font-family: 'Montserrat', sans-serif;
}

.letter-spacing-1 {
  letter-spacing: 4.75px;
}
.letter-spacing-2 {
  letter-spacing: 2.7px;
}
.letter-spacing-3 {
  letter-spacing: 2.35px;
}

.uppercase {
  text-transform: uppercase;
}

.fw-800 {
  font-weight: 800;
}

.fw-700 {
  font-weight: 700;
}

.fs-900 {
  font-size: clamp(5rem, 8vw + 1rem, 9.375rem);
}
.fs-800 {
  font-size: 3.5rem;
}

.fs-700 {
  font-size: 2.75rem;
}

.fs-600 {
  font-size: 1.875rem;
}

.fs-550 {
  font-size: 1.5rem;
}

.fs-525 {
  font-size: 1.25rem;
}

.fs-500 {
  font-size: 1rem;
}
.fs-400 {
  font-size: 0.875rem;
}

.fs-900,
.fs-800,
.fs-700,
.fs-600 {
  line-height: 1.1;
}

/* ------------------------------- */
/* TEXT DECORATION                 */
/* ------------------------------- */

.hover-items:hover {
  text-decoration: underline;
  text-decoration-color: rgba(110, 231, 183, 0.63);
  text-decoration-thickness: 2px;
  text-underline-offset: 0.5rem;
}

@media (min-width: 45em) {
  .hover-items:hover {
    text-decoration: underline;
    text-decoration-color: rgba(110, 231, 183, 0.63);
    text-decoration-thickness: 4px;
    text-underline-offset: 0.5rem;
  }
}
