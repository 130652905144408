input[type='file'] {
  width: 500px;
  max-width: 100%;
  color: #444;
  padding: 5px;

  border-radius: 10px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background-color: rgba(110, 231, 183, 0.63);
}
