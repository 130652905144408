label input {
  display: none; /* Hide the default checkbox */
}

/* Style the artificial checkbox */
label span {
  height: 15px;
  width: 15px;
  border: 1px solid grey;
  display: inline-block;
  position: relative;
}

/* Style its checked state...with a ticked icon */
[type='checkbox']:checked + span:before {
  content: '\2714';
  position: absolute;
  top: -4px;
  left: 1px;
}
