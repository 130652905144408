/* ------------------------------- */
/* TEXT & TITLES                   */
/* ------------------------------- */

.header-text {
  font-size: 1rem;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
}

@media (min-width: 45em) {
  .header-text {
    font-size: 1.5rem;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
  }
}

/* ------------------------------- */
/* LOGO PROPERTIES                 */
/* ------------------------------- */

.logo-size {
  width: 10rem;
  height: 3rem;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
}

@media (min-width: 45em) {
  .logo-size {
    width: 20rem;
    height: 5rem;
    padding: 1rem 1rem 1rem 1rem;
  }
}

/* ------------------------------- */
/* BORDERS                         */
/* ------------------------------- */

.header-border {
  border-bottom: 3px solid rgba(74, 85, 104, 0.1);
  box-shadow: 5px 3px 5px rgba(74, 85, 104, 0.1);
}

/* ------------------------------- */
/* PADDINGS AND MARGINS            */
/* ------------------------------- */

.right-margin {
  margin-right: 2rem;
}

.left-margin {
  margin-left: 1rem;
}

/* ------------------------------- */
/* DROPDOWN MENU                   */
/* ------------------------------- */

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

.dropdown-items-padding {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.dropdown-box {
  border-bottom: 3px solid rgba(74, 85, 104, 0.1);
  box-shadow: 5px 3px 5px 3px rgba(74, 85, 104, 0.1);
  border-radius: 0.375rem;
  margin-top: 0.75rem;
  position: absolute;
  right: 0;
  transform-origin: top-right;
  width: 14rem;
}

.dropdown-transition {
  visibility: hidden;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --tw-translate-y: -0.5rem;
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
  transform-origin: top-right;
}

/* ------------------------------- */
/* ICONS                           */
/* ------------------------------- */

div > * .hamburger-icon {
  width: 1.5rem;
  height: 0.25rem;
  background-color: rgba(74, 85, 104, 1);
  margin: 0.25rem 0;
}

.show-header-items {
  display: none;
}

.show-hamburger {
  display: block;
}

@media (min-width: 45em) {
  .show-header-items {
    display: flex;
  }

  .show-hamburger {
    display: none;
  }
}

.svg-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.625rem;
}
