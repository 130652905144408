/* ------------------------------- */
/* TEXT & TITLES                   */
/* ------------------------------- */

.home-title {
  font-size: 1.25rem;
  font-weight: 800;
  margin: 0 0.5rem 0 0.5rem;
  letter-spacing: 2.35;
}

.title-padding {
  padding: 1rem 0 1rem 0;
}

.features-margin {
  margin-top: 1rem;
  margin-left: 1rem;
}

.features-text {
  text-decoration: underline;
  text-decoration-color: rgba(110, 231, 183, 0.63);
  text-decoration-thickness: 4px;
  text-underline-offset: 0.5rem;
}

.small-features-text {
  text-decoration: underline;
  text-decoration-color: rgba(110, 231, 183, 0.63);
  text-decoration-thickness: 3px;
  /* text-underline-offset: 0rem; */
}

@media (min-width: 35em) {
  .home-title {
    font-size: 1.75rem;
    font-weight: 800;
  }
}

@media (min-width: 45em) {
  .home-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 3.125rem;
    line-height: 3.75rem;
    letter-spacing: -2.5px;
  }

  .title-padding {
    padding: 4rem 0 4rem 0;
  }

  .features-margin {
    margin-top: 4rem;
    margin-left: 2rem;
  }
}

.home-text {
  font-family: Montserrat;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 0.5rem;
}

@media (min-width: 45em) {
  .home-text {
    font-style: normal;
    font-weight: 500;
    max-width: 43rem;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: -1px;
  }
}

.footer {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -1px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.footer-text {
  margin: 1rem;
  color: rgba(74, 85, 104, 1);
}

@media (min-width: 45em) {
  .footer {
    display: flex;
    justify-content: space-around;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 50px;
    letter-spacing: -1.5px;
    flex-grow: 1;
    margin: 1rem;
  }
}

/* ------------------------------- */
/* BUTTONS                         */
/* ------------------------------- */

.large-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: -0.015em;
  width: 6.25rem;
  height: 3rem;
  z-index: 1;
  border-radius: 20px;
}

@media (min-width: 45em) {
  .large-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 44px;
    letter-spacing: -0.015em;
    width: 17.8rem;
    height: 5.125rem;
    z-index: 1;
    border-radius: 20px;
  }
}

.button-border {
  border: 3px solid rgb(220, 220, 220);
}

.button-hover:hover {
  background: rgba(110, 231, 183, 1);
}

/* ------------------------------- */
/* CONTAINERS AND GRIDS            */
/* ------------------------------- */

.grid-container {
  justify-items: center;
  margin: 0 1rem 0 1rem;
}

@media (min-width: 45em) {
  .grid-container {
    display: grid;
    grid-template-columns: 2em, repeat(2, minmax(0, 500px)), 2em;
  }

  .grid-container > *:first-child {
    grid-column: 2;
  }

  .grid-container > *:last-child {
    grid-column: 3;
  }
}

/* ------------------------------- */
/* BORDERS                         */
/* ------------------------------- */

.footer-border {
  border-top: 3px solid rgba(74, 85, 104, 0.1);
  box-shadow: 5px 3px 5px rgba(74, 85, 104, 0.1);
}
